import Vue from 'vue'
import { Modal, Toast} from 'narasi-bootstrap'

Vue.mixin({
  methods: {
    getBootstrapModal(id) {
      return new Modal(id)
    },
  },
})
