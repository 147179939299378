export default function ({ app, $axios, $store }) {
  $axios.onRequest((config) => {
    if (config.method !== "get") {
      app.store.commit("config/setLoading", true);
    }
    // window.$nuxt.$root.$loading.start();
  });

  $axios.onResponse((config) => {
    if (
      config.status !== 200 &&
      config.status !== 401 &&
      config.status !== 404 &&
      config.status !== 201 &&
      config.status !== 202 &&
      config.status !== 400
    ) {
      console.log("Error >>>>>>>>>>>:", config);
    }

    app.store.commit("config/setLoading", false);
    // window.$nuxt.$root.$loading.finish();
  });

  $axios.onError((res) => {
    // console.log(res, 'error 2');
    app.store.commit("config/setLoading", false);
    // window.$nuxt.$root.$loading.finish();
  });
}
